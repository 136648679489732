import React from "react";
import {
    PaidOutlined as PaidOutlinedIcon,
    ReceiptLong as ReceiptLongIcon,
    ListAlt as ListAltIcon,
    Edit as EditIcon,
} from "@mui/icons-material";
import GenericCollapse from "./GenericCollapse";
import GenericNavListItem from "./GenericNavListItem";
import GenericListItemButton from "./MenuItemWithSubmenu";

const AccountingMenu = ({
    userPermissions,
    openMenuAccounting,
    handleClickMenuAccounting,
    activeStyle,
}) => {
    return (
        <>
            {userPermissions?.includes("ACCOUNTING") && (
                <>
                    <GenericListItemButton
                        onClick={handleClickMenuAccounting}
                        className={"layout-menu-submenu"}
                        title="Contabilidad"
                        IconComponent={PaidOutlinedIcon}
                        openMenu={openMenuAccounting}
                    />
                    <GenericCollapse
                        className={"layout-menu-submenu-options"}
                        openMenu={openMenuAccounting}
                        userPermissions={userPermissions}
                    >
                        {/* {userPermissions?.includes("ACCOUNTING") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="ACCOUNTING"
                                to="dashboard"
                                title="Conciliación"
                                IconComponent={CompareArrowsIcon}
                                activeStyle={activeStyle}
                            />
                        )} */}
                        {userPermissions?.includes("ACCOUNTING") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="ACCOUNTING"
                                to="/listado/ipb"
                                title="Listado IPB"
                                IconComponent={ListAltIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                        {userPermissions?.includes("ACCOUNTING") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="ACCOUNTING"
                                to="/facturar"
                                title="Facturar"
                                IconComponent={ReceiptLongIcon}
                                activeStyle={activeStyle}
                            />
                        )}

                        {userPermissions?.includes("ACCOUNTING") && (
                            <GenericNavListItem
                                userPermissions={userPermissions}
                                permission="ACCOUNTING"
                                to="/editar/datos-fiscales"
                                title="Editar Datos Fiscales"
                                IconComponent={EditIcon}
                                activeStyle={activeStyle}
                            />
                        )}
                    </GenericCollapse>
                </>
            )}
        </>
    );
};

export default AccountingMenu;
