import React from "react";
import { Grid } from "@mui/material";

const ClientInfo = ({ client }) => (
    <Grid container className="device-info">
        <Grid item xs={12}>
            <h2>Cliente</h2>
            <div>
                <span>Nombre:</span>{" "}
                {client?.name ? client.name : "No disponible"}
            </div>
            <div>
                <span>Teléfono:</span>{" "}
                {client?.phone ? client.phone : "No disponible"}
            </div>
            <div>
                <span>Correo:</span>{" "}
                {client?.email ? client.email : "No disponible"}
            </div>
            <div>
                <span>Dirección:</span>{" "}
                {client?.address ? client.address : "No disponible"}
            </div>
            <hr></hr>
            <div>
                <span>Correo del ticket:</span>{" "}
                {client?.ticket_email ? client.ticket_email : "No disponible"}
            </div>
        </Grid>
    </Grid>
);

export default ClientInfo;
