import { Grid, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import OrderInfoGrid from "./componentsDetails/OrderInfo";
import PayerInfo from "./componentsDetails/PayerInfo";
import PaymentInfo from "./componentsDetails/PaymentInfo";
import IdentifiersGrid from "./componentsDetails/OrderIdentifiersGrid";
import PreventionSection from "./componentsDetails/FraudAndChargebackSection";
import OrderAdditionalInfo from "./componentsDetails/OrderAdditionalInfo";
import TransactionCard from "./componentsDetails/TransactionCard";

const DataTableDetailOrder = (props) => {
    const {
        response,
        handleOpenPopUp,
        type,
        onClose,
        namePayment,
        setIdReverso,
        idTransaction,
    } = props || {};

    const [title, setTitle] = useState("Tipo de Transacción");

    // useEffect(() => {
    //     console.log(response);
    // }, [response]);

    const fraudStyle = {
        fontWeight: 800,
        fontSize: "17px",
        color: "#ef5350",
    };

    const chargebackStyle = {
        fontWeight: 800,
        fontSize: "17px",
        color: "#f57c00",
    };

    const isLoading = !response;
    const hasPaymentInfo = response?.payment_info !== null;

    let orderDetails;

    switch (type) {
        case "detailsHistoryPrepaid":
            orderDetails = response?.prepaid;
            break;
        case "detailsSimIpb":
            orderDetails = response?.ipb;
            break;
        default:
            orderDetails = response?.payment_info;
            break;
    }

    return (
        <>
            {isLoading && <CircularProgress />}

            <Grid
                container
                spacing={5}
                className="device-info-container m-b-15"
            >
                {!isLoading && (
                    <>
                        {hasPaymentInfo ? (
                            <>
                                <OrderInfoGrid
                                    title={
                                        type === "detailsHistoryPrepaid"
                                            ? "Detalles del Producto"
                                            : type === "detailsSimIpb"
                                              ? "Detalles del Producto"
                                              : "Beneficiario"
                                    }
                                    type={type}
                                    orderDetails={orderDetails}
                                />
                                <Grid item xs={6} direction={"column"}>
                                    <div style={{ marginTop: "20px" }}></div>
                                    <PayerInfo
                                        type={type}
                                        title={
                                            type === "detailsHistoryPrepaid"
                                                ? "Detalles"
                                                : type === "detailsSimIpb"
                                                  ? "Detalles del pedido"
                                                  : "Información del Pagador"
                                        }
                                        orderDetails={orderDetails}
                                    />

                                    <div style={{ marginTop: "20px" }}></div>

                                    <OrderAdditionalInfo
                                        type={type}
                                        title={
                                            type === "detailsHistoryPrepaid"
                                                ? "Fechas de Transacción"
                                                : type === "detailsSimIpb"
                                                  ? "Fechas de Transacción"
                                                  : "Información Adicional"
                                        }
                                        orderDetails={orderDetails}
                                    />
                                    <div style={{ marginTop: "20px" }}></div>

                                    {type !== "detailsSimIpb" && (
                                        <PreventionSection
                                            type={type}
                                            title={"Prevención"}
                                            orderDetails={orderDetails}
                                            handleOpenPopUp={handleOpenPopUp}
                                            fraudStyle={fraudStyle}
                                            chargebackStyle={chargebackStyle}
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={6} direction={"column"}>
                                    <div style={{ marginTop: "20px" }}></div>
                                    <PaymentInfo
                                        type={type}
                                        title={
                                            type === "detailsHistoryPrepaid"
                                                ? "Detalles del Distribuidor y Descuento"
                                                : type === "detailsSimIpb"
                                                  ? "Dirección del Beneficiario"
                                                  : "Información del Pago"
                                        }
                                        orderDetails={orderDetails}
                                    />
                                    <div style={{ marginTop: "20px" }}></div>
                                    {type !== "detailsSimIpb" && (
                                        <IdentifiersGrid
                                            type={type}
                                            title={
                                                type === "detailsHistoryPrepaid"
                                                    ? "Detalles de la Respuesta"
                                                    : type === "detailsSimIpb"
                                                      ? "Identificadores"
                                                      : "Identificadores"
                                            }
                                            orderDetails={orderDetails}
                                        />
                                    )}

                                    <div style={{ marginTop: "20px" }}></div>
                                    <TransactionCard
                                        namePayment={namePayment}
                                        type={type}
                                        setTitle={setTitle}
                                        title={title}
                                        transactionType={
                                            response?.transaction_type
                                                ?.payment_type ||
                                            response?.payment_provider?.name
                                        }
                                        idTransaction={idTransaction}
                                        onClose={onClose}
                                        payment_type={
                                            response?.transaction_type
                                                ?.payment_type
                                        }
                                        isLoading={isLoading}
                                        setIdReverso={setIdReverso}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Typography variant="h6" color="textSecondary">
                                No hay información disponible.
                            </Typography>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
};

export default DataTableDetailOrder;
