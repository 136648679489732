import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import animationData from "assets/images/generate-invoice/loader.json";
export const useInvoiceForm = ({
    postGenerateInvoices,
    generateInvoices,
    cleanGenerateInvoices,
}) => {
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");
    const [invoiceLinks, setInvoiceLinks] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (generateInvoices?.status === 200) {
            setLoading(false);
            setResponseMessage("Facturación generada exitosamente.");
            setInvoiceLinks(generateInvoices?.payload?.result);
            setShowForm(false);
            cleanGenerateInvoices();
            reset();
        } else if (generateInvoices?.payload?.response?.status > 300) {
            setLoading(false);
            setResponseMessage(
                generateInvoices?.payload?.response?.data?.detail ||
                    "Hubo un error al generar las facturas."
            );
        }
    }, [generateInvoices, reset]);

    const onSubmit = (data) => {
        setLoading(true);
        setResponseMessage("");
        data.quantity = parseFloat(data.quantity);
        data.total_amount = parseFloat(data.total_amount);
        postGenerateInvoices(data);
    };

    const handleNegativeValue = (e) => {
        if (e.target.value < 0) {
            setValue(e.target.name, 0);
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const handleNewInvoice = () => {
        setShowForm(true);
        setInvoiceLinks([]);
        setResponseMessage("");
    };

    return {
        loading,
        responseMessage,
        invoiceLinks,
        showForm,
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleNegativeValue,
        defaultOptions,
        handleNewInvoice,
    };
};
