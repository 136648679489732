import React from "react";
import { Grid } from "@mui/material";

const PaymentInfo = ({ payment, order_details }) => (
    <Grid container className="device-info">
        <Grid item xs={12}>
            <h2>Forma de pago</h2>
        </Grid>
        <Grid item xs={6}>
            <div>
                <span>Tarjeta</span>
            </div>
            <div>
                <span>No. de autorización</span>
            </div>
            <div>
                <span>Ejecutivo</span>
            </div>
        </Grid>
        <Grid item xs={6}>
            <div style={{ textAlign: "right" }}>{payment?.maked_card}</div>
            <div style={{ textAlign: "right" }}>
                {payment?.authorization_number}
            </div>
            <div style={{ textAlign: "right" }}>
                {order_details?.backoffice_user_name ?? "No disponible"}
            </div>
        </Grid>
    </Grid>
);

export default PaymentInfo;
