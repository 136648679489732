import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

export const UseListIpbHook = ({
    ipbInvoiceListAction,
    cleanIPBInvoiceListAction,
    ipbInvoiceList,
}) => {
    const { payload } = ipbInvoiceList || {};
    const { rows, pagination } = payload || {};

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [action, setAction] = useState(null);
    const [openActionFraud, setOpenActionFraud] = useState(false);
    const [loadingFraudAndChargeback, setLoadingFraudAndChargeback] =
        useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [error, setError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (isSubmitted || page !== 1 || size !== 10) {
            const filteredSearchParams = Object.fromEntries(
                Object.entries(searchParams).filter(
                    ([_, value]) => value !== undefined && value !== ""
                )
            );
            setLoading(true);
            ipbInvoiceListAction(page, size, filteredSearchParams)
                .then(() => {
                    console.log("ipbInvoiceListAction success");
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("ipbInvoiceListAction error", error);
                    setLoading(false);
                });
        }
        return () => {
            cleanIPBInvoiceListAction();
        };
    }, [
        page,
        size,
        searchParams,
        ipbInvoiceListAction,
        cleanIPBInvoiceListAction,
        isSubmitted,
    ]);
    useEffect(() => {
        if (ipbInvoiceList?.status === 200) {
            setLoading(false);
        } else if (ipbInvoiceList?.payload?.response?.status > 200) {
            setDialogMessage(ipbInvoiceList?.payload?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [ipbInvoiceList]);

    const onSubmit = (data) => {
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(
                ([_, value]) => value !== undefined && value !== ""
            )
        );

        setLoading(true);
        setPage(1);
        setSearchParams(filteredData);
        setIsSubmitted(true);
        setErrorMessage("");
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangePageSize = (event) => {
        const newSize = parseInt(event.target.value, 10);
        setSize(newSize);
        setPage(1);
    };

    const mappedRows =
        rows?.map((row) => ({
            ...row,
            id: row.uuid,
        })) || [];

    const handleClose = () => {
        setIsDialogOpen(false);
        setIsDialogOpenDetails(false);
        // cleanDetailNetPayAction();
    };

    const handleOpenPopUp = (action, rowId) => {
        setAction(action);
        // setSelectedRowId(rowId);
        setOpenActionFraud(true);
    };

    const headerNameMapping = {
        amount: "Monto",
        carrier_name: "Proveedor",
        created: "Fecha DB",
        currency: "Moneda",
        invoice_date: "Fecha de Factura",
        emitter_legal_id: "ID del Emisor",
        invoice_type: "Tipo de Factura",
        is_cancelled: "Cancelado",
        receiver_legal_id: "ID del Receptor",
        stamped_at: "Fecha de Timbre",
        uuid: "Uuid",
    };

    const Columns = [
        {
            field: "uuid",
            headerName: headerNameMapping["uuid"],
            width: 400,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "amount",
            headerName: headerNameMapping["amount"],
            width: 120,
            align: "right",
            headerAlign: "center",
            valueGetter: (params) => {
                const formattedAmount = Number(
                    params.row.amount
                ).toLocaleString("es-MX", {
                    style: "currency",
                    currency: "MXN",
                });
                return formattedAmount;
            },
        },
        {
            field: "carrier_name",
            headerName: headerNameMapping["carrier_name"],
            width: 250,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "created",
            headerName: headerNameMapping["created"],
            width: 250,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.created);
            },
        },
        {
            field: "currency",
            headerName: headerNameMapping["currency"],
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "invoice_date",
            headerName: headerNameMapping["invoice_date"],
            width: 250,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params.row.invoice_date);
            },
        },
        {
            field: "emitter_legal_id",
            headerName: headerNameMapping["emitter_legal_id"],
            width: 220,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "invoice_type",
            headerName: headerNameMapping["invoice_type"],
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "is_cancelled",
            headerName: headerNameMapping["is_cancelled"],
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (params.value ? "Sí" : "No"),
        },
        {
            field: "receiver_legal_id",
            headerName: headerNameMapping["receiver_legal_id"],
            width: 200,
            align: "right",
            headerAlign: "center",
        },
        {
            field: "stamped_at",
            headerName: headerNameMapping["stamped_at"],
            width: 200,
            align: "center",
            headerAlign: "center",
        },
    ];

    const fields = [
        { name: "from_date", label: "Desde", type: "date", xl: 1 },
        { name: "to_date", label: "Hasta", type: "date", xl: 1 },
        {
            name: "uuid",
            label: "UUID",
            type: "text",
            xl: 2,
        },
        { name: "amount", label: "Monto", type: "number", xl: 1 },
    ];

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        rows,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        setErrorMessage,
        openActionFraud,
        setError,
        loadingFraudAndChargeback,
        error,
        handleOpenPopUp,
        fields,
    };
};
