import React from "react";
import { Grid } from "@mui/material";
import OrderInfo from "../components/OrderInfo";
import ClientInfo from "../components/ClientInfo";
import DeviceInfo from "../components/DeviceInfo";
import FraudPrevention from "../components/FraudPrevention";
import { chargebackStyle, fraudStyle } from "../style/datagridStyle";

const OrderSection = ({
    order_details,
    dateSale,
    client,
    device,
    labelSimType,
    handleOpenPopUp,
}) => (
    <>
        <OrderInfo order_details={order_details} dateSale={dateSale} />
        <Grid item xs={6} direction={"column"}>
            <ClientInfo client={client} />
            <div style={{ marginTop: "20px" }}></div>
            <DeviceInfo device={device} labelSimType={labelSimType} />
            <div style={{ marginTop: "20px" }}></div>
            <Grid item xs={6}>
                <FraudPrevention
                    order_details={order_details}
                    handleOpenPopUp={handleOpenPopUp}
                    fraudStyle={fraudStyle}
                    chargebackStyle={chargebackStyle}
                />
            </Grid>
        </Grid>
    </>
);

export default OrderSection;
