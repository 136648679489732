import React from "react";
import { Dialog, DialogContent, Button } from "@mui/material";
import TextFieldWithError from "./TextFieldWithError";
import AddressFields from "./AddressFields";

const ChangeSimDialog = ({
    isDialogOpen,
    handleCloseDialog,
    handleSubmit,
    handleCopyToClipboard,
    register,
    registerOptions,
    description,
    handleDescriptionChange,
    errors,
    order_details,
    newOne,
    newTwo,
    setValue,
    inputChange,
    stateShrink,
    municipalityShrink,
    neighborhoodShrink,
    states,
    municipalities,
    neighborhoods,
    zipcodeShrink,
    addressShrink,
    referenceInput,
    contactPhoneShrink,
}) => (
    <Dialog open={isDialogOpen}>
        <DialogContent style={{ width: "500px" }}>
            <form onSubmit={handleSubmit(handleCopyToClipboard)}>
                <TextFieldWithError
                    register={register}
                    registerOptions={registerOptions?.description}
                    label="Descripción"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={description}
                    onChange={handleDescriptionChange}
                    errors={errors}
                    errorKey="description"
                    name="description"
                />
                {order_details?.sim_type_id === 2 && (
                    <AddressFields
                        register={register}
                        registerOptions={registerOptions}
                        newOne={newOne}
                        newTwo={newTwo}
                        setValue={setValue}
                        inputChange={inputChange}
                        errors={errors}
                        states={states}
                        municipalities={municipalities}
                        neighborhoods={neighborhoods}
                        stateShrink={stateShrink}
                        municipalityShrink={municipalityShrink}
                        neighborhoodShrink={neighborhoodShrink}
                        zipcodeShrink={zipcodeShrink}
                        addressShrink={addressShrink}
                        referenceInput={referenceInput}
                        contactPhoneShrink={contactPhoneShrink}
                    />
                )}
                <Button variant="contained" color="primary" type="submit">
                    Cambiar
                </Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </form>
        </DialogContent>
    </Dialog>
);

export default ChangeSimDialog;
