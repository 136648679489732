import React from "react";
import { Grid, Typography } from "@mui/material";

const OrderInfo = ({ order_details, dateSale }) => (
    <Grid
        container
        className="device-info"
        xl={12}
        style={{ marginLeft: "50px", marginTop: "50px" }}
    >
        <Grid item xs={6}>
            <Typography variant="body1">
                <strong>Número Dalefon:</strong> {order_details?.direct_number}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="body1">
                <strong>Lead ID:</strong> {order_details?.lead_id}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="body1">
                <strong>Número de orden:</strong> {order_details?.order_number}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="body1">
                <strong>Fecha de compra:</strong> {dateSale}
            </Typography>
        </Grid>
    </Grid>
);

export default OrderInfo;
