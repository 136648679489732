import { useContext, useEffect } from "react";
import { MonthlySalesContext } from "../data/context";

export const useMonthlySalesReportHook = () => {
    const monthlySalesContext = useContext(MonthlySalesContext);

    const { redux, states } = monthlySalesContext || {};
    const { getSalesMonthly } = redux || {};
    const {
        setComparativeColumns,
        setComparativeRows,
        cells,
        setCells,
        headers,
        setHeaders,
        columns,
        setColumns,
        rows,
        setRows,
        data,
        setData,
        noDataForGrid,
        setNoDataForGrid,
    } = states || {};

    const generateColumns = () => {
        const columnsArray = [
            {
                field: "type_esim",
                headerName: "eSIM",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "type_sim",
                headerName: "SIM",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "total",
                headerName: "Total",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
        ];

        setColumns(columnsArray);
    };

    const generateRows = () => {
        const { payload } = getSalesMonthly || {};
        const { result } = payload || {};

        const comparativeColumnsArray = [];
        const headersArray = [];
        const rowsSet = new Set();

        result?.forEach((element) => {
            const { date, product_name } = element || {};

            const hName = date?.toString().replaceAll(" ", "_");
            const rName = product_name?.toString().replaceAll(" ", "_");

            rowsSet.add(rName);

            const compare = headersArray?.includes(hName);

            if (!compare) {
                headersArray.push(hName);

                if (headersArray.length > 1) {
                    comparativeColumnsArray.push(
                        headersArray[headersArray.length - 2] + " / " + date
                    );
                }
            }
        });

        setComparativeColumns(comparativeColumnsArray);
        setHeaders(headersArray);

        // Convertir el set directamente en un array
        const rowsArray = Array.from(rowsSet);
        setRows(rowsArray);

        const records = result?.reduce((obj, item) => {
            const { date, product_name, sim_type, quantity } = item || {};

            const hName = date?.toString().replaceAll(" ", "_");
            const rName = product_name?.toString().replaceAll(" ", "_");

            const key = `${hName}=${rName}`;
            const existingRecord = obj[key];

            if (!existingRecord) {
                obj[key] = {
                    esim: sim_type === "E-SIM" ? quantity : 0,
                    sim: sim_type === "E-SIM" ? 0 : quantity,
                    total: quantity,
                    hName,
                    rName,
                };
            } else {
                existingRecord.esim += sim_type === "E-SIM" ? quantity : 0;
                existingRecord.sim += sim_type === "E-SIM" ? 0 : quantity;
                existingRecord.total += quantity;
            }

            return obj;
        }, {});

        setData(records);

        if (result?.length === 0) {
            setNoDataForGrid(true);
        } else {
            setNoDataForGrid(false);
        }
    };

    useEffect(() => {
        const rws = data ? Object.keys(data).map((key) => data[key]) : [];

        const rowsArray = [];

        rows?.forEach((row, rowIndex) => {
            const cellsArray = [];
            headers?.forEach((header, headerIndex) => {
                cellsArray.push(setTotalValues({ header, row, rws }));
            });

            rowsArray.push(cellsArray);
        });

        setCells(rowsArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        const rowsArray = [];
        cells?.forEach((cell, cellIndex) => {
            const cellsArray = [];

            cells?.forEach((cell, cellIndex) => {
                const cellsArray = [];

                cell.forEach((cll, cllIndx) => {
                    if (cllIndx > 0) {
                        let value = 0;

                        if (cell[cllIndx - 1] !== 0) {
                            value =
                                (parseFloat(cll) /
                                    parseFloat(cell[cllIndx - 1]) -
                                    1) *
                                100;
                        }

                        cellsArray.push(value);
                    }
                });
            });

            rowsArray.push(cellsArray);
        });
        setComparativeRows(rowsArray);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cells]);

    const setTotalValues = (params = {}) => {
        const { header, row, rws } = params || {};
        const value = !isNaN(
            parseFloat(
                rws?.filter(
                    (entry) =>
                        entry.hName === header &&
                        entry.rName === row.replaceAll(" ", "_")
                )[0]?.total
            )
        )
            ? parseFloat(
                  rws?.filter(
                      (entry) =>
                          entry.hName === header &&
                          entry.rName === row.replaceAll(" ", "_")
                  )[0]?.total
              )
            : 0;

        return value;
    };

    useEffect(() => {
        generateColumns();
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        generateRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSalesMonthly]);

    return { headers, columns, rows, data, noDataForGrid, getSalesMonthly };
};
