import React from "react";
import { Grid } from "@mui/material";
import PurchaseSummary from "../components/PurchaseSummary";
import PaymentInfo from "../components/PaymentInfo";
import Actions from "../components/Actions";
import ChangeSimDialogComponent from "../components/ChangeSimDialogComponent";
import CustomDialogComponent from "../components/CustomDialogComponent";

const SummarySection = ({
    product,
    payment,
    order_details,
    getEsimQr,
    handleOpenDialog,
    handleRequest,
    loading,
    isDialogOpen,
    handleCloseDialog,
    handleSubmit,
    handleCopyToClipboard,
    register,
    registerOptions,
    description,
    handleDescriptionChange,
    errors,
    newOne,
    newTwo,
    setValue,
    inputChange,
    stateShrink,
    municipalityShrink,
    neighborhoodShrink,
    states,
    municipalities,
    neighborhoods,
    zipcodeShrink,
    addressShrink,
    referenceInput,
    contactPhoneShrink,
    isCustomDialogOpen,
    dialogMessage,
    setIsCustomDialogOpen,
    getSaleOrderDetailAction,
    simOrderId,
    onClose,
}) => (
    <Grid item xs={6} direction={"column"}>
        <PurchaseSummary product={product} />
        <div style={{ marginTop: "20px" }}></div>
        <PaymentInfo payment={payment} order_details={order_details} />

        <div style={{ marginTop: "20px" }}></div>

        <Actions
            order_details={order_details}
            getEsimQr={getEsimQr}
            handleOpenDialog={handleOpenDialog}
            handleRequest={handleRequest}
            loading={loading}
        />

        <Grid container className="hidden-element">
            <Grid item xs={6}>
                <span>
                    <div>Reembolso</div>
                </span>
            </Grid>
            <Grid item xs={6} className="device-right-data">
                <span>
                    <p>-ButtonGenerar-</p>
                </span>
            </Grid>
        </Grid>

        <ChangeSimDialogComponent
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            handleSubmit={handleSubmit}
            handleCopyToClipboard={handleCopyToClipboard}
            register={register}
            registerOptions={registerOptions}
            description={description}
            handleDescriptionChange={handleDescriptionChange}
            errors={errors}
            order_details={order_details}
            newOne={newOne}
            newTwo={newTwo}
            setValue={setValue}
            inputChange={inputChange}
            stateShrink={stateShrink}
            municipalityShrink={municipalityShrink}
            neighborhoodShrink={neighborhoodShrink}
            states={states}
            municipalities={municipalities}
            neighborhoods={neighborhoods}
            zipcodeShrink={zipcodeShrink}
            addressShrink={addressShrink}
            referenceInput={referenceInput}
            contactPhoneShrink={contactPhoneShrink}
        />

        <CustomDialogComponent
            isCustomDialogOpen={isCustomDialogOpen}
            dialogMessage={dialogMessage}
            setIsCustomDialogOpen={setIsCustomDialogOpen}
            getSaleOrderDetailAction={getSaleOrderDetailAction}
            simOrderId={simOrderId}
            onClose={onClose}
        />
    </Grid>
);

export default SummarySection;
