import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { getEsimQr } from "_models/redux/esim-qr/action";
import { getSaleOrderDetailAction } from "_models/redux/sales-detail-order/action";
import { cleanChangeSimToEsimAction } from "_models/redux/change-sim-esim/clean-action";
import { cleanChangeEsimToSimAction } from "_models/redux/change-esim-sim/clean-action";
import { changeSimToEsimAction } from "_models/redux/change-sim-esim/action";
import { changeEsimToSimAction } from "_models/redux/change-esim-sim/action";
import { municipalityAction } from "_models/redux/sepomex-municipality/action";
import { neighborhoodAction } from "_models/redux/sepomex-neighborhood/action";
import { simActivationAction } from "_models/redux/sim-activation/action";
import { postTransactionsFraud } from "_models/redux/detail-transaction-fraud/action";
import { postTransactionsChargeback } from "_models/redux/detail-transaction-chargeback/action";
import { cleanTransactionChargeback } from "_models/redux/detail-transaction-chargeback/clean_action.js";
import { cleanTransactionFraud } from "_models/redux/detail-transaction-fraud/clean_action";
import { getSaleOrdersAction } from "_models/redux/sales-orders/action";
import FraudDialog from "../components/FraudDialog";
import OrderSection from "../components/OrderSection";
import SummarySection from "../components/SummarySection";
import useDataTableDetailOrderController from "../hooks/useDataTableDetailOrderController";
const DataTableDetailOrder = (props) => {
    const controllerProps = useDataTableDetailOrderController(props);

    return (
        <Grid container spacing={5} className="device-info-container m-b-15">
            <OrderSection
                order_details={controllerProps.order_details}
                dateSale={controllerProps.dateSale}
                client={controllerProps.client}
                device={controllerProps.device}
                labelSimType={controllerProps.labelSimType}
                handleOpenPopUp={controllerProps.handleOpenPopUp}
            />
            <SummarySection
                onClose={controllerProps.onClose}
                product={controllerProps.product}
                payment={controllerProps.payment}
                order_details={controllerProps.order_details}
                getEsimQr={controllerProps.getEsimQr}
                handleOpenDialog={controllerProps.handleOpenDialog}
                handleRequest={controllerProps.handleRequest}
                loading={controllerProps.loading}
                isDialogOpen={controllerProps.isDialogOpen}
                handleCloseDialog={controllerProps.handleCloseDialog}
                handleSubmit={controllerProps.handleSubmit}
                handleCopyToClipboard={controllerProps.handleCopyToClipboard}
                register={controllerProps.register}
                registerOptions={controllerProps.registerOptions}
                description={controllerProps.description}
                handleDescriptionChange={
                    controllerProps.handleDescriptionChange
                }
                errors={controllerProps.errors}
                newOne={controllerProps.newOne}
                newTwo={controllerProps.newTwo}
                setValue={controllerProps.setValue}
                inputChange={controllerProps.inputChange}
                stateShrink={controllerProps.stateShrink}
                municipalityShrink={controllerProps.municipalityShrink}
                neighborhoodShrink={controllerProps.neighborhoodShrink}
                states={controllerProps.states}
                municipalities={controllerProps.municipalities}
                neighborhoods={controllerProps.neighborhoods}
                zipcodeShrink={controllerProps.zipcodeShrink}
                addressShrink={controllerProps.addressShrink}
                referenceInput={controllerProps.referenceInput}
                contactPhoneShrink={controllerProps.contactPhoneShrink}
                isCustomDialogOpen={controllerProps.isCustomDialogOpen}
                dialogMessage={controllerProps.dialogMessage}
                setIsCustomDialogOpen={controllerProps.setIsCustomDialogOpen}
                getSaleOrderDetailAction={
                    controllerProps.getSaleOrderDetailAction
                }
                simOrderId={controllerProps.simOrderId}
            />
            <FraudDialog
                openActionFraud={controllerProps.openActionFraud}
                handleClose={controllerProps.handleClose}
                fraudChargebackPopupTitle={
                    controllerProps.fraudChargebackPopupTitle
                }
                setDescriptionTransaction={
                    controllerProps.setDescriptionTransaction
                }
                setError={controllerProps.setError}
                error={controllerProps.error}
                loadingFraudAndChargeback={
                    controllerProps.loadingFraudAndChargeback
                }
                dialogMessage={controllerProps.dialogMessage}
                isError={controllerProps.isError}
                handleConfirm={controllerProps.handleConfirm}
                detailTransactionChargeback={
                    controllerProps.detailTransactionChargeback
                }
                detailTransactionFraud={controllerProps.detailTransactionFraud}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    saleOrderDetail: state?.saleOrderDetail,
    saleOrderEsimQr: state?.saleOrderEsimQr,
    municipality: state?.municipality,
    neighborhood: state?.neighborhood,
    changeEsimToSim: state?.changeEsimToSim,
    changeSimToEsim: state?.changeSimToEsim,
    simActivation: state?.simActivation,
    detailTransactionFraud: state?.detailTransactionFraud,
    detailTransactionChargeback: state?.detailTransactionChargeback,
});

const mapDispatchToProps = {
    getEsimQr,
    municipalityAction,
    neighborhoodAction,
    getSaleOrderDetailAction,
    cleanChangeSimToEsimAction,
    cleanChangeEsimToSimAction,
    changeSimToEsimAction,
    changeEsimToSimAction,
    simActivationAction,
    postTransactionsChargeback,
    postTransactionsFraud,
    cleanTransactionChargeback,
    cleanTransactionFraud,
    getSaleOrdersAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataTableDetailOrder);
