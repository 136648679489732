import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import TableComponent from "widgets/table/component";
import TableManagerActivations from "./components/table-row";
import { Chart } from "react-google-charts";
import BarChartIcon from "@mui/icons-material/BarChart";

const ActivationsDeactivationsChart = (props) => {
    const { parentProperties } = props || {};
    const { redux } = parentProperties || {};

    const { activationsAndDeactivation } = redux || {};

    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [dataForGrid, setDataForGrid] = useState([]);
    const [noDataForGrid, setNoDataForGrid] = useState(false);

    const options = {
        height: 500,
        legend: { position: "top", maxLines: 3 },
        vAxis: { title: "No. de Activaciones y Desactivaciones" },
        isStacked: "absolute",
        curveType: "none",
    };

    const generateColumns = () => {
        const columnsGrid = [
            {
                field: "date",
                headerName: "Fecha",
                width: 150,
                position: "sticky",
                headerAlign: "center",
            },

            {
                field: "activations",
                headerName: "Activaciones",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "deactivations",
                headerName: "Desactivaciones",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
        ];

        setColumns(columnsGrid);
    };

    const generateRows = () => {
        const itemRows = [];
        if (dataForGrid !== undefined) {
        }
        dataForGrid?.forEach((element, index) => {
            itemRows.push({
                id: index,
                date: element[0],
                activations: element[1],
                deactivations: element[2],
            });
        });

        setRows(itemRows);
    };

    useEffect(() => {
        generateColumns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        generateRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForGrid]);

    useEffect(() => {
        const { payload } = activationsAndDeactivation || {};

        let totalA = 0;
        let totalD = 0;

        if (payload) {
            const rows = Object?.keys(payload).map((key) => payload[key]);
            const dataArray = [];
            const dataArrayGrid = [];

            dataArray.push([
                "Fecha",
                "Activaciones",
                "Desactivaciones",
                { role: "annotation" },
            ]);

            if (rows?.length > 0) {
                rows?.forEach((item, index) => {
                    const rows1 = Object?.keys(item).map((key) => item[key]);

                    rows1?.forEach((itm, indx) => {
                        const numberOfActivations = itm?.activations || 0;
                        const numberodDeactivations = itm?.deactivations || 0;
                        totalA = totalA + parseInt(numberOfActivations);
                        totalD = totalD + parseInt(numberodDeactivations);
                        dataArray.push([
                            itm?.date || 0,
                            itm?.activations || 0,
                            itm?.deactivations || 0,
                            "",
                        ]);
                        dataArrayGrid.push([
                            itm?.date || 0,
                            itm?.activations || 0,
                            itm?.deactivations || 0,
                            "",
                        ]);
                    });
                });
            } else {
                dataArray.push([0, 0]);
                dataArrayGrid.push([0, 0]);
            }
            dataArrayGrid.push(["Total", totalA, totalD]);
            setData(dataArray);
            setDataForGrid(dataArrayGrid);
            if (payload?.result?.length === 0) {
                setNoDataForGrid(true);
            } else {
                setNoDataForGrid(false);
            }
        }
    }, [activationsAndDeactivation]);

    return (
        <Grid container spacing={5}>
            {activationsAndDeactivation.loaded === true &&
            noDataForGrid === false ? (
                <Grid item xs={12}>
                    <h3>Activaciones y desactivaciones</h3>
                    <span className="line"></span>

                    <Grid container mt={7}>
                        <Grid item xs={12} md={12}>
                            <Chart
                                chartType="LineChart"
                                width="100%"
                                height="auto"
                                data={data}
                                options={options}
                            />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <TableComponent
                                columns={columns}
                                rowsComponent={
                                    <TableManagerActivations rows={rows} />
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : noDataForGrid ? (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    height={345}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>No contiene datos, por favor intenta otra fecha </p>
                    </div>
                </Grid>
            ) : (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    height={363}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>Selecciona una fecha para búsqueda de datos </p>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default ActivationsDeactivationsChart;
