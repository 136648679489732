import React from "react";
import { Grid, Divider } from "@mui/material";

const PurchaseSummary = ({ product }) => (
    <Grid container className="device-info">
        <Grid item xs={12}>
            <h2>Resumen de compra</h2>
        </Grid>
        <Grid item xs={6}>
            <div>
                <span>Producto</span>
            </div>
            <div>
                <span>Costo</span>
            </div>
            <div>
                <span>Costo SIM/eSIM</span>
            </div>
            <div>
                <span>Costo envío</span>
            </div>
        </Grid>
        <Grid item xs={6}>
            <div style={{ textAlign: "right" }}>{product?.name}</div>
            <div style={{ textAlign: "right" }}>${product?.prepaid_cost}</div>
            <div style={{ textAlign: "right" }}>${product?.sim_esim_cost}</div>
            <div style={{ textAlign: "right" }}>${product?.shipping_cost}</div>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={6}>
            <div>
                <p>Total</p>
            </div>
        </Grid>
        <Grid item xs={6}>
            <div style={{ textAlign: "right" }}>
                <p>${product?.total_amount}</p>
            </div>
        </Grid>
    </Grid>
);

export default PurchaseSummary;
