import React from "react";
import { Grid } from "@mui/material";

const DeviceInfo = ({ device, labelSimType }) => (
    <Grid container className="device-info">
        <Grid container>
            <Grid item xs={6}>
                <div>
                    <span>Marca:</span> {device?.brand}{" "}
                </div>
                <div>
                    <span>Modelo:</span> {device?.model}{" "}
                </div>
                <div>
                    <span>IMEI:</span> {device?.imei}
                </div>
            </Grid>
            <Grid item xs={6} className="device-right-data">
                <Grid
                    container
                    xs={12}
                    className="type-label"
                    id={labelSimType()}
                >
                    <div>{labelSimType()}</div>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

export default DeviceInfo;
